export default {
  async selectTfichero (Vue, esTecnico, esComercial, esVigilante) {
    return Vue.$offline.tfichero.selectTficheroDisponibles(esTecnico, esComercial, esVigilante)
  },
  async selectFormData (Vue, id) {
    return (await Vue.$offline.fichero.rowFichero(id))[0].fichero
  },
  async selectCarpetasDisponibles(Vue, idtabla, id) {
    return (await Vue.$offline.fichero.selectCarpetasDisponibles(idtabla, id))
  }
}
